define('survey/routes/business_skills', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function redirect() {
            this.transitionTo('business_skills_assess');
        }
    });
});