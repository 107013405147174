define('survey/components/business-skills-autoeval', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isValid: function isValid() {
			var errors = [];
			var dim = this.get('autoevals');
			for (var ae in dim) {
				if (dim[ae].value === null || isNaN(dim[ae].value) || parseInt(dim[ae].value) < 0 || parseInt(dim[ae].value) > 100) {
					errors.push("L'auto-evaluation a " + dim[ae].name + " doit être entre 0 et 100");
				}
			}
			this.set('errors', errors);
			return errors.length === 0;
		},

		actions: {
			submit: function submit() {
				if (this.isValid()) {
					var ret = {};
					var aes = this.get('autoevals');
					for (var code in aes) {
						ret[code] = parseInt(aes[code].value);
					}
					this.get('next')(ret);
				}
			}
		}
	});
});