//import DS from 'ember-data';

//const { String: { pluralize, underscore } } = Ember;

/*
export default DS.JSONAPIAdapter.extend({
    host: 'http://dev.successtools.fr/',
    namespace: 'survey'/*,
  headers: Ember.computed('session.authToken', function() {
    return {
      'API_KEY': this.get('session.authToken'),
      'ANOTHER_HEADER': 'Some header value'
    };*/
//});

//import Ember from 'ember';
//import JSONAPIAdapter from 'ember-data/adapters/json-api';
//,  pathForType(type) {
//    return pluralize(underscore(type));}
define("survey/adapters/application", [], function () {
  "use strict";
});