define('survey/router', ['exports', 'survey/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('business_skills', { path: 'business_skills/:token' });
        this.route('business_skills_assess', { path: 'business_skills_assess/:token' });
        this.route('business_skills_training', { path: 'business_skills_training/:token' });
        this.route('404');
        this.route('thanks');
    });

    exports.default = Router;
});