define('survey/components/business-skills-step', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isValid: function isValid() {
			var tab = [];
			var errors = [];
			this.get('question')['answers'].forEach(function (v, index) {
				var i = parseInt(v['value']);
				if (isNaN(i) || i < 1 || i > 4) {
					errors.push("La réponse " + (index + 1).toString() + " doit être entre 1 et 4");
				}
				tab.push(i);
			});
			if (tab.length !== tab.uniq().length) {
				errors.push("2 réponses ne peuvent avoir la même valeur.");
			}
			this.set('errors', errors);
			return errors.length === 0;
		},

		actions: {
			submit: function submit() {
				if (this.isValid()) {
					var ret = { 'validated_at': Date.now() };
					var answers = this.get('question')['answers'];
					answers.forEach(function (answer) {
						ret[answer['code']] = parseInt(answer['value']);
					});
					this.get('next')(ret);
				}
			}
		}
	});
});