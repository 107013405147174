define('survey/components/business-skills-training', ['exports', 'survey/config/environment'], function (exports, _environment) {
			'use strict';

			Object.defineProperty(exports, "__esModule", {
						value: true
			});
			exports.default = Ember.Component.extend({
						humanIndex: Ember.computed('index', function () {
									return this.get('index') + 1;
						}),
						question: Ember.computed('index', function () {
									return this.get('questions')[this.get('index')];
						}),
						ajax: Ember.inject.service(),
						init: function init() {
									this._super();
									this.set('index', 0);
									this.set('errors', []);
									this.set('hasErrors', false);
									var self = this;
									this.set('cb_timeout', Ember.run.later(function () {
												self.set('hasTimeouted', true);
									}, 600000));
									this.set('data', { 'started_at': Date.now() });
						},
						willDestroyElement: function willDestroyElement() {
									Ember.run.cancel(this.get('cb_timeout'));
						},


						actions: {
									next: function next(answer) {
												var data = this.get('data');
												var index = this.get('index');
												data[this.get('question')['code']] = answer;
												this.set('data', data);
												if (index + 1 === this.get('questions').length) {
															this.send('submit');
															return;
												}
												this.set('index', index + 1);
									},
									submit: function submit() {
												var _this = this;

												var form = this;
												var ret = this.get('data');
												ret['ended_at'] = Date.now();
												this.get('ajax').request(_environment.default.APP.apiURL + '/business_skills_training/' + this.get('token'), {
															'type': 'PUT',
															'data': { 'link': { 'results': ret } }
												}).then(function (data) {
															Ember.run.cancel(_this.get('cb_timeout'));
															form.set('hasErrors', false);
															_this.get('afterSubmit')(data);
															return true;
												}).catch(function (error) {
															form.set('hasErrors', true);
															if (error.errors !== undefined) {
																		form.set('errors', error.errors);
															} else {
																		form.set('errors', error);
															}
															return false;
												});
									}
						}
			});
});