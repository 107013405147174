define('survey/components/business-skills-assess', ['exports', 'survey/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		humanIndex: Ember.computed('index', function () {
			return this.get('index') + 1;
		}),
		question: Ember.computed('index', function () {
			return this.get('questions')[this.get('index')];
		}),
		ajax: Ember.inject.service(),
		init: function init() {
			this._super();
			this.set('index', 0);
			this.set('errors', []);
			this.set('hasErrors', false);
			var self = this;
			this.set('cb_timeout', Ember.run.later(function () {
				self.set('hasTimeouted', true);
			}, 600000));
			this.set('data', { 'started_at': Date.now() });
			this.set('part1', true);
			this.set('part2', false);
			this.set('autoevals', { ae1: { name: 'Préparation', value: null },
				ae2: { name: 'Présentation', value: null },
				ae3: { name: 'Prise de contact', value: null },
				ae4: { name: 'Argumentation', value: null },
				ae5: { name: 'Réponse aux objections', value: null },
				ae6: { name: 'Conclusion', value: null },
				ae7: { name: 'Suivi du contact', value: null
					//ae8: {name: 'Réactivité', value: null}
				} });
		},
		willDestroyElement: function willDestroyElement() {
			Ember.run.cancel(this.get('cb_timeout'));
		},


		actions: {
			handleAutoeval: function handleAutoeval(aes) {
				var data = this.get('data');
				var tmp = Object.assign({}, data, aes);
				this.set('data', tmp);
				this.send('submit');
			},
			handleAnswer: function handleAnswer(answer) {
				var data = this.get('data');
				var index = this.get('index');
				data[this.get('question')['code']] = answer;
				this.set('data', data);
				if (index + 1 === this.get('questions').length) {
					this.set('part1', false);
					this.set('part2', false); //set to true to enable auto-evaluation
					this.set('hasErrors', false);
					Ember.run.cancel(this.get('cb_timeout'));
					this.send('submit'); // comment that to enable auto-evaluation
				} else {
					this.set('index', index + 1);
				}
			},
			submit: function submit() {
				var _this = this;

				var form = this;
				var ret = this.get('data');
				ret['ended_at'] = Date.now();
				this.get('ajax').request(_environment.default.APP.apiURL + '/business_skills_assess/' + this.get('token'), {
					'type': 'PUT',
					'data': { 'link': { 'results': ret } }
				}).then(function (data) {
					Ember.run.cancel(_this.get('cb_timeout'));
					form.set('hasErrors', false);
					_this.get('afterSubmit')(data);
					return true;
				}).catch(function (error) {
					form.set('hasErrors', true);
					if (error.errors !== undefined) {
						form.set('errors', error.errors);
					} else {
						form.set('errors', error);
					}
					return false;
				});
			}
		}
	});
});