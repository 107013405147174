define('survey/controllers/business_skills_training', ['exports'], function (exports) {
			'use strict';

			Object.defineProperty(exports, "__esModule", {
						value: true
			});
			exports.default = Ember.Controller.extend({
						init: function init() {
									this.set('isStarted', false);
						},

						actions: {
									start: function start() {
												this.toggleProperty('isStarted');
									},
									finish: function finish() {
												this.transitionToRoute('thanks');
									}
						}
			});
});