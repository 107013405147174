define('survey/routes/business_skills_training', ['exports', 'survey/config/environment'], function (exports, _environment) {
			'use strict';

			Object.defineProperty(exports, "__esModule", {
						value: true
			});
			exports.default = Ember.Route.extend({
						model: function model(params) {
									return Ember.$.getJSON(_environment.default.APP.apiURL + '/business_skills_training/' + params.token).then(function (data) {
												return data;
									});
						},

						actions: {
									error: function error() /*error, transition*/{
												this.replaceWith('404');
									}
						}
			});
});